export const menuItems = [
  {
    title: 'dashboard',
    icon: 'mdi-chart-bar-stacked',
    to: 'Dashboard',
    roles: ['admin', 'partnerAdmin'],
  },
  {
    title: 'partnerPrice',
    icon: 'mdi-moped-electric-outline',
    to: 'PartnerPrice',
    roles: ['admin', 'partnerAdmin'],
  },
  {
    title: 'quotes',
    icon: 'mdi-sale',
    to: 'Quotes',
    roles: ['admin', 'partnerAdmin'],
  },
  {
    title: 'manualOffer',
    icon: 'mdi-star',
    to: 'ManualOffer',
    roles: ['admin', 'partnerAdmin'],
  },
  {
    title: 'orders',
    icon: 'mdi-bag-personal',
    to: 'Orders',
  },
  {
    title: 'search',
    icon: 'mdi-home-search-outline',
    group: 'search',
    roles: ['admin', 'partnerAdmin'],
    children: [
      {
        title: 'stokab',
        icon: 'mdi-layers-search-outline',
        to: 'SearchStokab',
      },
      {
        title: 'sollentuna',
        icon: 'mdi-blinds',
        to: 'SearchSollentuna',
      },
      {
        title: 'openinfra',
        icon: 'mdi-brightness-5',
        to: 'SearchOpeninfra',
      },
      // {
      //   title: 'openuniverse',
      //   icon: 'mdi-ferry',
      //   to: 'SearchOpenuniverse',
      // },
      {
        title: 'ipOnly',
        icon: 'mdi-ip-network',
        to: 'SearchIPOnly',
      },
      {
        title: 'zitius',
        icon: 'mdi-access-point',
        to: 'SearchZitius',
      },
    ],
  },
  {
    title: 'manage',
    icon: 'mdi-desktop-mac-dashboard',
    group: 'manage',
    roles: ['admin', 'partnerAdmin'],
    children: [
      {
        title: 'manageDomains',
        icon: 'mdi-wan',
        to: 'ManageDomains',
        roles: ['admin'],
      },
      {
        title: 'consumptions',
        icon: 'mdi-form-select',
        to: 'Consumptions',
        roles: ['admin'],
      },
      {
        title: 'consumptionDevices',
        icon: 'mdi-form-select',
        to: 'ConsumptionDevices',
        roles: ['admin'],
      },
      {
        title: 'requestPriceList',
        icon: 'mdi-currency-usd',
        to: 'RequestPriceList',
      },
      {
        title: 'searchedAddress',
        icon: 'mdi-map-marker',
        to: 'SearchedAddress',
      },
      {
        title: 'accesses',
        icon: 'mdi-access-point-network',
        to: 'UpdateAccess',
        roles: ['admin'],
      },
      {
        title: 'products',
        icon: 'mdi-server-network',
        to: 'Products',
      },
      {
        title: 'ipOnlyOrders',
        icon: 'mdi-ip-network',
        to: 'IpOnlyOrders',
      },
      {
        title: 'zitiusOrders',
        icon: 'mdi-phone-voip',
        to: 'ZitiusOrders',
      },
      {
        title: 'parkedZitiusOrders',
        icon: 'mdi-access-point-network-off',
        to: 'ParkedZitiusOrders',
      },
      {
        title: 'subscriptions',
        icon: 'mdi-database-sync',
        to: 'Subscriptions',
      },
      {
        title: 'addOns',
        icon: 'mdi-expansion-card-variant',
        to: 'AddOns',
      },
      {
        title: 'users',
        icon: 'mdi-account-multiple',
        to: 'Users',
      },
      {
        title: 'partners',
        icon: 'mdi-handshake',
        to: 'Partners',
        roles: ['admin'],
      },
      {
        title: 'updateCompanyInfo',
        icon: 'mdi-domain',
        to: 'UpdateCompanyInfo',
        roles: ['partnerAdmin'],
      },
    ],
  },
]
