<template>
  <v-navigation-drawer
    v-model="themeMode.verticalSidebarDrawer"
    class="sidebar-six"
    dark
    height="100%"
    width="280px"
    :mobile-breakpoint="960"
    :floating="true"
    app
  >
    <vue-perfect-scrollbar
      :settings="{ suppressScrollX: true, wheelPropagation: false }"
      class="ps"
      style="height: 100%"
    >
      <div class="pa-4 top-toolbar d-flex align-center">
        <v-avatar
          size="36"
          class="white"
        >
          <img
            :src="logoUrl"
            alt="Servercentralen sign up"
            class="rounded-0"
            style="width: 40px; height: auto;"
          >
        </v-avatar>
        <div class="white--text text-h6 ml-4">
           {{ companyName }}
        </div>
      </div>

      <v-divider class="mb-5" />

      <v-list
        class="py-0 mx-4"
        dense
      >
        <template v-for="(item, i) in availabeSideMenuItems">
          <base-list-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          />

          <base-list-item-icon
            v-else
            :key="`item-${i}`"
            :item="item"
          />
        </template>
        <v-list-item
          v-ripple="{ class: 'primary--text' }"
          @click="logout"
        >
          <v-list-item-icon class="min-w-0">
            <v-icon class="text-16 vertical-sidebar-icon">
              mdi-logout
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="ma-0 text-13">
              {{ $t("sidebar.logout") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </vue-perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { menuItems } from '../../data/navigation'

  import util from '@/utils'

  export default {
    data () {
      return {
        selectedItem: 0,
        group: null,
        items: menuItems,
        showMenu: false,
      }
    },

    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
        themeMode: 'getThemeMode',
      }),

      availabeSideMenuItems () {
        if (!this.currentUser) {
          return []
        }

        let sideMenuItems = menuItems.filter((menuItem) => {
          if (!menuItem.roles) return true

          return menuItem.roles.includes(this.currentUser.role)
        })

        sideMenuItems = sideMenuItems.map((menuItem) => {
          if (menuItem.children) {
            const childItems = menuItem.children.filter((childMenuItem) => {
              if (!childMenuItem.roles) return true

              return childMenuItem.roles.includes(this.currentUser.role)
            })

            menuItem.children = childItems
          }

          return menuItem
        })
        return sideMenuItems
      },

      logoUrl() {
        if (this.currentUser.partner?.logo) {
          return `${util.publicURL}/_partner_logos/${this.currentUser.partner.logo}`
        }

        return '/static/img/logo.png'
      },

      companyName() {
        return this.currentUser.partner?.companyName || 'Servercentralen'
      },
    },

    methods: {
      logout () {
        localStorage.clear('access_token')
        localStorage.clear('role')
        this.$router.push('/')
        window.location.reload()
      },
    },
  }
</script>

<style lang="scss">
.bg-sidebar-six {
  background-color: #292f3d;
}
.theme--dark .v-navigation-drawer {
  .bg-sidebar-six {
    background-color: #3a3939;
  }
}
.sidebar-six {
  .v-list-item__icon {
    margin-right: 8px !important;
    // margin-bottom: 0;
    // margin-top: 0;
  }

  .v-list-item--link:before {
    border-radius: 8px;
  }
}
</style>
